export default function state() {
  return {
    locale: 'en-ca',

    globalData: {},

    meta: {
      pageTitle: null,
      pageDescription: null,
      pageImage: null,
      pageType: null,
      seoMeta: {
        metaTitle: null,
        metaDescription: null,
        metaKeywords: null,
        metaImage: null,
      },
    },

    user: {
      data: null,
      shopifyToken: {},
      isCookieBarOpen: false,
      recharge: null,
    },

    cart: {
      items: [],
      checkout: null,
      checkoutUrl: null,
      menu: [],
      callout: null,
      showDiscountNote: null,
      register: {
        text: null,
        linkText: null,
      },
      enableTieredDiscount: false,
    },

    search: {
      menu: [],
      trendingProduct: null,
      callout: null,
    },

    header: {
      isHovered: false,
      isMenuOpen: false,
      isExpand: false,
      isDropdown: false,
      activeMenu: null,
      isSubmenuHovered: false,
      isSubmenuOpen: false,
      activeSubmenu: null,
      isMobileSubmenuOpen: false,
      activeMobileSubmenu: null,
      review: {
        product: null,
        handle: null,
      },
      preCheckRegisterNewsletter: false,
      links: {
        shop: [],
        learn: [
          {
            type: 'about',
            title: 'About',
          },
          {
            type: 'blog',
            title: 'Beauty Edit',
            mobileTitle: 'Beauty Edit Blog',
            to: '/beauty-edit',
          },
        ],
        studios: [
          {
            type: 'studios',
            title: 'Studios',
            description: '',
            heading: '',
            image: {},
            links: [],
          },
        ],
      },
    },

    modal: {
      isOpen: false,
      activeModal: null,
      quickShop: {
        type: null,
        modalTitle: null,
        modalProducts: [],
        activeProductUid: null,
        activeProductVariantId: null,
      },
      backInStockProduct: null,
      contact: {
        subject: null,
        booking: {
          studio: null,
          service: null,
        },
      },
    },

    smile: null,
  }
}
