import Cookie from 'js-cookie'

import { prismicClient } from '../plugins/apollo/prismic'

import {
  fetchShopMenuItems,
  fetchGlobalDataQuery,
  fetchAdditionalGlobalDataQuery,
} from '../prismic/queries/global'
import { getCustomerData } from '../shopify/customer'

import { recursivelyFetchAllProductTypes } from '../services/MegaMenuService'

import { getLocaleOnServer, getLocaleOnClient } from '../services/LocaleService'

// Global
export const nuxtServerInit = async function ({ dispatch }, { req }) {
  const locale = getLocaleOnServer(req.headers)

  await dispatch('setLocale', locale)
  await dispatch('fetchGlobalData')
  await dispatch('fetchShopMenus')

  this.$shopify.initClient(locale)
}

export const nuxtClientInit = async function ({ commit }) {
  const locale = getLocaleOnClient(window.location.hostname)

  await commit('SET_LOCALE', locale)
  this.$shopify.initClient(locale)
}

export const setLocale = ({ commit }, locale) => {
  commit('SET_LOCALE', locale)
}

export const checkAccessToken = async ({ commit, state }) => {
  const cookie = Cookie.get(`gee_access_token_${state.locale}`)

  if (cookie) {
    const token = decodeURIComponent(
      cookie.replace(`gee_access_token_${state.locale}=`, '')
    )

    commit('SET_ACCESS_TOKEN', JSON.parse(token))
  }
}

export const setGhostReferralAnalyticsCookies = () => {
  Cookie.set('ghost_ref', 'true', { expires: 365 })
}

export const checkCookieBarClosed = async ({ commit, state }) => {
  const isClosed = Cookie.get(`gee_cookie_bar_closed_${state.locale}`)

  if (isClosed) {
    commit('CLOSE_COOKIE_BAR')
  } else {
    commit('OPEN_COOKIE_BAR')
  }
}

export const fetchGlobalData = async ({ commit }) => {
  try {
    const globalData = await prismicClient.query({
      query: fetchGlobalDataQuery,
    })

    commit('SET_GLOBAL_DATA', globalData.data.allGlobals.edges[0].node)
  } catch (error) {
    console.log(error)
  }
}

export const setLocalStorageCartItems = async ({ commit, dispatch, state }) => {
  if (!process.client) return null

  const items =
    JSON.parse(localStorage.getItem(`gee_cart_${state.locale}`)) || []

  const lineItems = []
  items.forEach(item => {
    const { isBundle, variantId, quantity } = item

    // Object holding the line item's Variant ID and its Cart Quantity
    if (!isBundle) {
      lineItems.push({ variantId, quantity })
    } else {
      lineItems.push({ variantId: item.bundleVariantId, quantity })

      item.products.forEach(bundleItem => {
        lineItems.push({ variantId: bundleItem.variantId, quantity })
      })
    }
  })

  lineItems.flat()

  // Update the Shopify Checkout with the Line Items
  await dispatch('updateCheckout', lineItems)

  commit('SET_CART_ITEMS', items)
}

export const fetchShopMenus = async ({ commit, state }) => {
  try {
    const { allShop_categorys } = (
      await prismicClient.query({ query: fetchShopMenuItems })
    ).data

    const { locale } = state
    const productTypes = await recursivelyFetchAllProductTypes(locale, '')

    const menuItems = allShop_categorys.edges.map(({ node }) => {
      const type = node._meta.uid

      const filteredProductTypes = productTypes
        .map(({ node }) => node)
        .filter(item => {
          if (item.shopCategory) return type === item.shopCategory._meta.uid
        })

      return { ...node, type, productTypes: filteredProductTypes }
    })

    commit('SET_SHOP_MENUS', menuItems)
  } catch (error) {
    console.log(error)
  }
}

export const fetchAdditionalGlobalData = async ({ commit, state }) => {
  try {
    const { data } = await prismicClient.query({
      query: fetchAdditionalGlobalDataQuery,
    })

    const globals = data.allGlobals.edges[0].node

    const about = {
      description: globals.header_about_description,
      image: globals.header_about_image,
      menu: globals.header_about_menu,
    }

    const blog = {
      description: globals.header_blog_description,
      links: data.allBlog_landings.edges
        .map(item => item.node)[0]
        .featured_categories.map(item => item.category),
      articles: data.allArticles.edges.map(item => item.node),
    }

    const studios = {
      heading: globals.header_studio_heading,
      description: globals.header_studio_description,
      image: globals.header_studio_image,
      links: data.allStudios.edges.map(item => {
        return {
          ...item.node,
          type: 'studios',
        }
      }),
    }

    const cart = {
      menu: globals.cart_menu,
      callout: globals.cart_callout,
      enableTieredDiscount:
        state.locale == 'en-ca'
          ? globals.enable_tiered_discount_ca
          : globals.enable_tiered_discount_us,
      showDiscountNote: globals.show_cart_discount_note,
      register: {
        text: globals.cart_register_text,
        ctaText: globals.cart_register_cta_text,
      },
      enableGiftWrap: globals.enable_gift_wrap,
      giftWrapProduct:
        state.locale == 'en-ca'
          ? globals.gift_wrap_product_ca
          : globals.gift_wrap_product_us,
    }

    const search = {
      menu: globals.search_menu,
      trendingProduct: globals.search_trending_product,
      callout: globals.search_callout,
    }

    commit('SET_ABOUT_MENU_DATA', about)
    commit('SET_BLOG_MENU_DATA', blog)
    commit('SET_STUDIOS_MENU_DATA', studios)
    commit('SET_CART_DATA', cart)
    commit('SET_SEARCH_DATA', search)
  } catch (error) {
    console.log(error)
  }
}

// Meta
export const setMetaInfo = async ({ commit }, payload) => {
  const { title, description, image, type, brand, price, meta, $liveChat } =
    payload

  const metaInfo = {
    pageTitle: title,
    pageDescription: description,
    pageImage: image,
    pageType: type ? type : 'website',
    productBrand: brand,
    productPrice: price,
  }

  if (meta) {
    metaInfo.seoMeta = {
      metaTitle: meta.meta_title,
      metaDescription: meta.meta_description,
      metaKeywords: meta.meta_keywords,
      metaImage: meta.meta_image,
    }
  }

  await commit('SET_META_INFO', metaInfo)

  if ($liveChat) $liveChat.update()
}

// User/Customer
export const setAccessToken = ({ commit, state }, token) => {
  Cookie.set(`gee_access_token_${state.locale}`, token)
  commit('SET_ACCESS_TOKEN', token)
}

export const removeAccessToken = ({ commit, state }, token) => {
  commit('REMOVE_ACCESS_TOKEN', token)
  Cookie.remove(`gee_access_token_${state.locale}`)
}

export const setUserData = async function ({ commit, dispatch, state }) {
  try {
    const { data } = await this.$shopify.client.query({
      query: getCustomerData,
      variables: {
        customerAccessToken: state.user.shopifyToken.accessToken,
      },
    })

    await commit('SET_USER_DATA', data.customer)
    dispatch('fetchSmileAccountData')
  } catch (err) {
    console.error(err)
  }
}

export const fetchSmileAccountData = async function ({ commit, state }) {
  if (!this.$smile) return null

  const email = state.user.data?.email

  if (!email) return null

  const customer = await this.$smile.fetchCustomerData(email)

  commit('SET_SMILE_DATA', customer)
}

export const removeUserData = ({ commit }) => {
  commit('REMOVE_USER_DATA')
  commit('REMOVE_SMILE_DATA')
}

export const closeCookieBar = ({ commit, state }) => {
  Cookie.set(`gee_cookie_bar_closed_${state.locale}`, true, { expires: 365 })
  commit('CLOSE_COOKIE_BAR')
}

// Header
export const openHeaderMenu = ({ commit }, type) => {
  commit('OPEN_HEADER_MENU', type)
  commit('CLOSE_HEADER_SUBMENU')
}

export const openHeaderMenuExpand = ({ commit }, type) => {
  commit('OPEN_HEADER_MENU', type)
  commit('SET_HEADER_MENU_EXPAND')
}

export const openHeaderMenuDropdown = ({ commit }, type) => {
  commit('CLOSE_HEADER_SUBMENU')
  commit('OPEN_HEADER_MENU', type)
  commit('SET_HEADER_MENU_DROPDOWN')
}

export const openRegisterWithNewsletterCheckedExpand = ({ commit }) => {
  commit('OPEN_HEADER_MENU', 'register')
  commit('SET_HEADER_MENU_EXPAND')
  commit('PRE_CHECK_REGISTER_NEWSLETTER')
}

export const openReviewExpand = ({ commit }, info) => {
  commit('OPEN_HEADER_MENU', 'leave-a-review')
  commit('SET_HEADER_MENU_EXPAND')
  commit('SET_REVIEW_EXPAND_INFO', info)
}

export const closeHeaderMenu = ({ commit }, isHover = false) => {
  commit('CLOSE_HEADER_MENU', isHover)
  commit('CLOSE_HEADER_SUBMENU')
  commit('CLOSE_HEADER_MOBILE_SUBMENU')
}

export const closeHeaderMenuImmediately = ({ commit }) => {
  commit('CLOSE_HEADER_MENU_IMMEDIATELY')
}

export const openHeaderSubmenu = ({ commit }, type) => {
  commit('OPEN_HEADER_SUBMENU', type)
}

export const closeHeaderSubmenu = ({ commit }) => {
  commit('CLOSE_HEADER_SUBMENU')
}

export const openHeaderMobileSubmenu = ({ commit }, type) => {
  commit('OPEN_HEADER_MOBILE_SUBMENU', type)
}

export const closeHeaderMobileSubmenu = ({ commit }) => {
  commit('CLOSE_HEADER_MOBILE_SUBMENU')
}

// Cart/Checkout
export const createShopifyCheckout = async function ({ commit }) {
  try {
    const checkout = await this.$shopify.initCheckout()

    commit('UPDATE_CHECKOUT', checkout)
    return checkout
  } catch (err) {
    console.error(err)
  }
}

// Accepts array of objects, holding the line items' variant IDs and cart Quantities
// UpdateCheckout commits a GQL mutation that updates the Shopify Checkout
// Returns a new Checkout object
export const updateCheckout = function (
  { commit, state, dispatch },
  newLineItems
) {
  return new Promise(async (resolve, reject) => {
    try {
      const checkoutToUpdate =
        state.cart.checkout ?? (await dispatch('createShopifyCheckout'))
      const checkout = await this.$shopify.updateCheckout(
        checkoutToUpdate,
        newLineItems
      )

      commit('UPDATE_CHECKOUT', checkout)
      resolve(checkout)
    } catch (err) {
      console.error(err)
      reject(err)
    }
  })
}

const checkIfItemInCart = (state, payload) => {
  if (!payload) return null

  const cartItems = state.cart.items

  const isInCart = cartItems.filter(item => {
    return item?.variantId == payload.variantId
  })

  return isInCart.length !== 0
}

export const addItemToCart = async ({ commit, state }, payload) => {
  if (!payload) return null

  const isItemInCart = checkIfItemInCart(state, payload)

  if (isItemInCart) {
    commit('UPDATE_ITEM_QUANTITY', payload)
  } else {
    commit('ADD_ITEM_TO_CART', payload)
  }
}

export const removeItemFromCart = ({ commit, dispatch }, payload) => {
  commit('REMOVE_ITEM_FROM_CART', payload.variantId)
}

export const updateItemQuantity = ({ commit }, payload) => {
  commit('UPDATE_ITEM_QUANTITY', payload)
}

// Modal
export const openModal = ({ commit }, type) => {
  commit('OPEN_MODAL', type)
}

export const changeQuickShopModalActiveProductId = (
  { commit },
  activeProductUid
) => {
  commit('CHANGE_QUICK_SHOP_MODAL_ACTIVE_PRODUCT_ID', activeProductUid)
}

export const changeQuickShopActiveProduct = ({ commit }, payload) => {
  commit('UPDATE_QUICK_SHOP_ACTIVE_PRODUCT', payload)
}

export const openQuickShopModal = ({ commit }, payload) => {
  commit('OPEN_MODAL', 'quick-shop')
  commit('SET_QUICK_SHOP_MODAL', payload)
}

export const setQuickShopModalTitle = ({ commit }, title) => {
  commit('SET_QUICK_SHOP_MODAL_TITLE', title)
}

export const setQuickShopModalProducts = ({ commit }, products) => {
  commit('SET_QUICK_SHOP_MODAL_PRODUCTS', products)
}

export const openBackInStockModal = ({ commit }, product) => {
  commit('OPEN_MODAL', 'back-in-stock')
  commit('SET_BACK_IN_STOCK_MODAL', product)
}

export const openContactModal = ({ commit }, subject) => {
  commit('OPEN_MODAL', 'contact')
  commit('SET_CONTACT_MODAL_SUBJECT', subject)
}

export const openBookingModal = ({ commit }, payload) => {
  commit('OPEN_MODAL', 'contact')
  commit('SET_BOOKING_MODAL_INFO', payload)
}

export const closeModal = ({ commit }) => {
  commit('CLOSE_MODAL')
}

export const setRechargeData = async function ({ commit }, email) {
  const data = await this.$bold.fetchCustomerData(email)

  commit('SET_RECHARGE_DATA', data)
}
