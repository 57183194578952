export const locale = state => state.locale

export const isLoggedIn = state => {
  const { shopifyToken } = state.user

  if (shopifyToken === null) return false

  const { accessToken, expiresAt } = shopifyToken

  if (!accessToken) return false

  const now = new Date()

  if (now.getTime() > Date.parse(expiresAt)) return false

  return true
}

export const nonGiftWrapItems = state => {
  return state.cart.items.filter(item => {
    if (!item) return false

    return !item.isGiftWrap
  })
}

export const cartTotal = (state, getters) => {
  if (getters.nonGiftWrapItems.length === 0) return 0.0

  const items = getters.nonGiftWrapItems

  const total = items.reduce((acc, lineItem) => {
    if (lineItem.isReward) return acc + 0

    const lineItemSum =
      parseFloat(
        lineItem.salePrice ? lineItem.salePrice : lineItem.price
      ).toFixed(2) * lineItem.quantity

    return acc + lineItemSum
  }, 0)

  return total.toFixed(2)
}

export const numCartItems = (state, getters) => {
  let numItems = 0

  const items = getters.nonGiftWrapItems

  if (items.length > 0) {
    numItems = items.reduce((acc, cur) => {
      return acc + cur.quantity
    }, numItems)
  }

  return numItems
}

export const cartHasSubscription = (state, getters) =>
  getters.nonGiftWrapItems.some(item => item.frequency === 'subscription')

export const domain = state => {
  return state.locale == 'en-ca'
    ? process.env.NUXT_ENV_DOMAIN_CA
    : process.env.NUXT_ENV_DOMAIN_US
}

export const shopifyUrl = state => {
  return (
    (state.locale == 'en-ca'
      ? process.env.NUXT_ENV_SHOPIFY_SHOP_NAME_CA
      : process.env.NUXT_ENV_SHOPIFY_SHOP_NAME_US) + '.myshopify.com'
  )
}

export const localStudio = state => {
  const studioLinks = state.header.links.studios[0].links

  if (studioLinks) {
    return studioLinks.find(studio => {
      return (
        studio.country == (state.locale == 'en-ca' ? 'Canada' : 'United States')
      )
    })
  }
}

export const localStudioServiceLink = (state, getters) => {
  const localStudio = getters.localStudio

  if (localStudio) {
    const firstCategoryUid = localStudio.services[0].service_category._meta.uid

    return `/studio/${localStudio._meta.uid}/${firstCategoryUid}`
  }
}
